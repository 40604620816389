var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// Lib
import React from "react";
import { Configure, connectHits, InstantSearch } from "react-instantsearch-dom";
// Components
import VenueHit from "../../search/components/VenueHit";
// Config
import { TypeSenseConfig } from "../../TypeSenseConfig";
// @ts-ignore
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
var typesenseInstantSearchAdapter = new TypesenseInstantSearchAdapter(TypeSenseConfig);
var searchClient = typesenseInstantSearchAdapter.searchClient;
var NearbyVenues = /** @class */ (function (_super) {
    __extends(NearbyVenues, _super);
    function NearbyVenues() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getHitsLayout = function () {
            return function (_a) {
                var hits = _a.hits;
                if (hits.length > 0) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement("hr", null),
                        React.createElement("div", { className: "related-venues" },
                            React.createElement("div", { className: "search__area" }, "View nearby venues"),
                            React.createElement("div", { className: "ais-Hits" },
                                React.createElement("div", { className: "ais-Hits-list" }, hits.map(function (hit) {
                                    return React.createElement("div", { className: "ais-Hits-item", key: "venue-".concat(hit.id) },
                                        React.createElement(VenueHit, { hit: hit }));
                                }))),
                            React.createElement("a", { href: "/?s=&page=1&latLng=".concat(_this.props.latLng, "&radius=5000&areaLabel=").concat(_this.props.areaLabel), className: "btn" }, "View venues"))));
                }
                else {
                    return null;
                }
            };
        };
        _this.render = function () {
            if (!_this.props.latLng.length)
                return (React.createElement(React.Fragment, null));
            var configuration = {
                hitsPerPage: 2,
                analytics: false,
                enablePersonalization: true,
                distinct: true,
                aroundLatLng: _this.props.latLng,
                aroundRadius: 30000,
            };
            var CustomHits = connectHits(_this.getHitsLayout());
            return (React.createElement(InstantSearch, { indexName: "wp_venue", searchClient: searchClient },
                React.createElement(Configure, __assign({}, configuration)),
                React.createElement(CustomHits, null)));
        };
        return _this;
    }
    return NearbyVenues;
}(React.PureComponent));
export default NearbyVenues;
